@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  p {
    font-family: 'Montserrat';
  }
  span {
    font-family: 'Montserrat';
  }
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --chart-1: 173 58% 39%;
    --chart-2: 12 76% 61%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    --radius: 0.5rem;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --chart-1: 220 70% 50%;
    --chart-5: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-2: 340 75% 55%;

    --radius: 0.5rem;

    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

body {
  margin: 0;
  overflow: overlay;
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply text-foreground;
    font-family: Montserrat, sans-serif;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }
}

/* Styles for WebKit browsers */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f7f4ed;
}

::-webkit-scrollbar-thumb {
  background: #e0cbcb;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c0a0b9;
}

/* Dark mode styles */

/* Styles for WebKit browsers */
::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #f7f4ed;
  margin: 8px;
}

::-webkit-scrollbar-thumb {
  background: #e0cbcb;
  border-radius: 100vh;
  border: 2px solid #f7f4ed;
}

::-webkit-scrollbar-thumb:hover {
  background: #c0a0b9;
}

/* Dark mode styles */

@layer utilities {
  [data-theme='dark'] ::-webkit-scrollbar-track {
    background: #2d3748;
  }

  [data-theme='dark'] ::-webkit-scrollbar-thumb {
    background: #4a5568;
    border: none;
  }

  [data-theme='dark'] ::-webkit-scrollbar-thumb:hover {
    background: #718096;
  }
  /* Scrollbar width and height */
  .scrollbar::-webkit-scrollbar {
    width: 8px; /* Increased width for better visibility */
    height: 12px;
  }

  /* Scrollbar track */
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
    margin: 8px; /* Add padding between content and scrollbar */
  }

  /* Scrollbar thumb */
  .scrollbar::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 2px solid #f7f4ed; /* Creates padding effect */
  }

  /* Scrollbar thumb hover */
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }

  /* Dark mode styles */
  .dark .scrollbar::-webkit-scrollbar-track {
    background: #2d3748; /* Dark background color */
  }

  .dark .scrollbar::-webkit-scrollbar-thumb {
    background: #4a5568; /* Dark thumb color */
    border: 2px solid #2d3748; /* Creates padding effect in dark mode */
  }

  .dark .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #718096; /* Dark hover color */
  }
}
