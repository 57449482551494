.app-checkbox {
    .ant-checkbox:hover {
      .ant-checkbox-inner {
        border-color: var(--checkbox-color) !important;
      }
    }
    .ant-checkbox-disabled {
      .ant-checkbox-inner {
        background-color: var(--normal-hover-border) !important;
        border-color: var(--normal-hover-border) !important;
      }
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: var(--checkbox-color) !important;
        border-color: var(--checkbox-color) !important;
      }
      .ant-checkbox-inner::after {
        border-color: #483e04 !important;
      }
    }
  }
  