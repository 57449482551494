@import 'src/assets/scss/variable.scss';

.mobile-menu-detail {
  background: $color-pure-white;
  color: $color12;
  height: 100vh;
  width: 100vw;

  .ant-menu-inline {
    border: none;
  }

  // menu redirect
  .ant-menu {
    background: transparent;

    .ant-menu-submenu,
    .ant-menu-item {
      font-size: 16px;
      color: $color12;
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding-left: 18px !important;
        color: $color12 !important;

        &:active,
        &:focus {
          background: transparent;
        }
      }

      .ant-menu-submenu-arrow {
        display: none;
      }

      .ant-menu-sub {
        background: transparent;

        .ant-menu-item-only-child {
          font-size: 14px;
          color: $color-grey-2;
          padding-left: 19px !important;

          a {
            color: $color-grey-2;
          }

          &::after {
            display: none;
          }
        }
      }
    }

    .ant-menu-item {
      font-size: 16px;
      color: $color12;

      &::after {
        display: none;
      }
    }

    .ant-menu-item-active,
    .ant-menu-item-selected {
      background: transparent;

      .ant-menu-title-content {
      }
    }

    .ant-menu-item.ant-menu-item-only-child {
      padding-left: 20px !important;
    }
  }

  // item
  &__item-wrapper {
    margin-bottom: 20px;

    &__item {
      display: flex;
      align-items: center;
      padding: 0px 21px;

      span {
        font-weight: 700;
        font-size: 16px;
      }

      .network {
        display: flex;
        align-items: center;
        width: 100%;
        height: 44px;
        border-radius: 8px;
        border: 1px solid $color-grey-5;
        padding: 10px 16px;
        background: transparent;

        img {
          margin-right: 8px;
        }

        span {
          font-size: 14px;

          color: $color-grey-2;
        }
      }

      .warning-network {
        background: $color11 !important;
        color: $color-pure-white !important;
      }

      .language-item {
        font-weight: 400;
        font-size: 14px;
        color: $color-grey-2;
      }

      .selected-language-item {
        @extend .language-item;

        color: $color12;
      }

      .button-connect {
        width: 100%;
        border: none;
        background: $color13;
        border-radius: 8px;
        height: 44px;

        span {
          color: $text-color-white;

          font-size: 14px;
        }
      }

      .my-tier-mobile-title {
        &__my-tier-title {
          display: inline;

          font-size: 16px;
        }
      }
    }

    .default-item {
      span {
        font-weight: 400;
        font-size: 14px;
      }
    }

    .item-icon {
      margin-right: 8px;
    }

    .back-icon {
      margin-right: 16px;
    }

    .check-icon {
      width: 12px;
      height: 12px;
    }
  }

  // common
  .dark-light-mode {
    display: flex;
    align-items: center;
    span {
      margin-right: 8px;

      font-size: 14px;
      color: $color12;
    }
  }

  .line {
    background: $color-grey-9;
    height: 1px;
    margin: 10px 21px;
    margin-bottom: 20px;
  }

  .visibility-hidden {
    visibility: hidden;
  }

  .justify-flex-start {
    justify-content: flex-start;
  }

  .justify-between {
    justify-content: space-between;
  }

  .mb-20 {
    margin-bottom: 26px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mr-16 {
    margin-right: 16px;
  }

  .pt-20 {
    padding-top: 20px;
  }
}
